import { mainAxios as axios, mainCacheAxios as cacheAxios } from '@/base/axios'
import {
  REQUEST_PAGE,
  RECEIVE_PAGE,
  REQUEST_POSTS,
  RECEIVE_POSTS,
  RESET_POSTS,
  REQUEST_POST,
  RECEIVE_POST,
  REQUEST_CPTS,
  RECEIVE_CPTS,
  REQUEST_CPT,
  RECEIVE_CPT,
  REQUEST_PAPERS,
  RECEIVE_PAPERS,
  REQUEST_TAXONOMIES,
  RECEIVE_TAXONOMIES,
} from '@/actions/types'
import { mergeArrays } from '@/utils/array'
import { API } from '@/constants'

const requestPage = () => ({ type: REQUEST_PAGE })

const receivePage = (data, slug, child) => ({
  type: RECEIVE_PAGE,
  payload: data,
  key: slug,
  child,
})

const fetchPage = (slug, child = false) => async (dispatch) => {
  dispatch(requestPage())
  const response = await cacheAxios.get(`${API.PAGE}?slug=${slug}`)
  const { id } = response.data[0]
  const responseChild = child && await cacheAxios.get(`${API.PAGE}?parent=${id}`)
  dispatch(receivePage(response.data[0], slug, responseChild.data))
}

const requestCpts = () => ({ type: REQUEST_CPTS })

const receiveCpts = (data, slug) => ({
  type: RECEIVE_CPTS,
  payload: data,
  key: slug,
})

const fetchCpts = (cpt, page = 1000) => async (dispatch) => {
  dispatch(requestCpts())
  const response = await cacheAxios.get(`${API.BASE_URL}/${cpt}?per_page=${page}`)
  dispatch(receiveCpts(response.data, cpt))
}

const requestCpt = () => ({ type: REQUEST_CPT })

const receiveCpt = (data, cpt) => ({
  type: RECEIVE_CPT,
  payload: data,
  key: cpt,
})

const fetchCpt = (cpt, slug) => async (dispatch) => {
  dispatch(requestCpt())
  const response = await cacheAxios.get(`${API.BASE_URL}/${cpt}?slug=${slug}`)
  dispatch(receiveCpt(response.data, cpt))
}

const requestPosts = () => ({ type: REQUEST_POSTS })

const receivePosts = (data, totalPosts, totalPages) => ({
  type: RECEIVE_POSTS,
  payload: data,
  totalPosts,
  totalPages,
})

const fetchAllPosts = (numItem, paged, categories, save, merge) => async (dispatch, getState) => {
  dispatch(requestPosts())
  const params = {
    params: {
      order: 'desc',
      orderby: 'date',
      per_page: numItem,
      page: paged,
      ...(categories && {
        categories,
      }),
    },
  }
  const response = await axios.get(API.POST, params)
  const posts = merge ? mergeArrays(getState().content.posts.items, response.data) : response.data
  if (save) {
    dispatch(receivePosts(
      posts,
      response.headers['x-wp-total'],
      response.headers['x-wp-totalpages'],
    ))
  }
  return response.data
}

const resetAllPosts = () => ({ type: RESET_POSTS })

const requestPost = () => ({ type: REQUEST_POST })

const receivePost = (data) => ({
  type: RECEIVE_POST,
  payload: data,
})

const fetchPost = (slug) => async (dispatch, getState) => {
  dispatch(requestPost())
  const response = await cacheAxios.get(`${API.POST}?slug=${slug}`)
  const posts = mergeArrays(getState().content.posts.items, response.data)
  dispatch(receivePost(posts))
}

const requestPapers = () => ({ type: REQUEST_PAPERS })

const receivePapers = (data, totalPapers, totalPages) => ({
  type: RECEIVE_PAPERS,
  payload: data,
  totalPapers,
  totalPages,
})

const fetchAllPapers = (numItem, paged, save) => async (dispatch, getState) => {
  dispatch(requestPapers())
  const params = {
    params: {
      order: 'desc',
      orderby: 'date',
      per_page: numItem,
      page: paged,
    },
  }
  const response = await axios.get(API.PAPERS, params)
  const papers = mergeArrays(getState().content.papers, response.data)
  if (save) {
    dispatch(receivePapers(
      papers,
      response.headers['x-wp-total'],
      response.headers['x-wp-totalpages'],
    ))
  }
  return response.data
}

const fetchPapersById = (ids) => async (dispatch) => {
  dispatch(requestPapers())
  // IDS = 419,476
  // https://sandbox.peptone.io/wp-json/wp/v2/_cty_papers?filter[orderby]=date&filter[order]=asc&include=419,476
  const response = await cacheAxios.get(`${API.PAPERS}?filter[orderby]=date&filter[order]=asc&include=${ids}`)
  return response.data
}

const fetchPapersByAuthor = (id) => async (dispatch) => {
  dispatch(requestPapers())
  const response = await cacheAxios.get(`${API.PAPERS}?author=${id}`)
  return response.data
}

const requestTaxonomies = () => ({ type: REQUEST_TAXONOMIES })

const receiveTaxonomies = (data) => ({
  type: RECEIVE_TAXONOMIES,
  payload: data,
})

const fetchTaxonomy = (slug) => async (dispatch, getState) => {
  dispatch(requestTaxonomies())
  const response = await axios.get(`${API.BASE_URL}/${slug}`)
  const taxonomies = mergeArrays(getState().content.taxonomies, response.data)
  dispatch(receiveTaxonomies(taxonomies))
  return response.data
}

export {
  fetchAllPapers,
  fetchPage,
  fetchCpts,
  fetchCpt,
  fetchAllPosts,
  resetAllPosts,
  fetchPost,
  fetchPapersById,
  fetchPapersByAuthor,
  fetchTaxonomy,
}
